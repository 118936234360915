import React, { useState } from "react"
// import LinkTransition from '../common/link-transition';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"
import ContentHero from "../components/content-hero"

import Ready from "../components/ready"

import illuReady from "../images/illu-ready.svg"

const ContentSample = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  const featuresList = [
    {
      title: "Organize together",
      details: [
        {
          title: "Channels",
          text: `Thanks to the channels you can define different workspaces with different user rights. That means you can keep your sensitive presentations confidential. You can also work in your very own channel when you want to play it solo.`,
        },
        {
          title: "Live collaboration",
          text: `Collaborate on the same presentation at the same time, without getting in the way of each other.`,
        },
        {
          title: "Dropbox",
          text: `Paste a link to a picture from your Dropbox into your slides to transform it into a smart link: if this picture is modified it gets magically updated into your slides.`,
        },
        {
          title: "Google Drive",
          text: `Login with your Google account, and sync your presentations with your Google Drive. `,
        },
        {
          title: "Adobe Creative Cloud Libraries",
          text: `Import images, videos, GIFs and colors from your Adobe Creative Creative Cloud Libraries.`,
        },
        {
          title: "Clone presentation",
          text: `Copy your presentation inside your current account or send it to another one (ie: send it to your team account).`,
        },
      ],
    },
    {
      title: "Create without limits",
      details: [
        {
          title: "Blend modes",
          text: `16 different blend modes are supported, including multiply, screen and overlay.`,
        },
        {
          title: "Color overlay",
          text: `Cover an image or an icon with an opaque or semi-transparent color.`,
        },
        {
          title: "Color picker",
          text: `Click and drag your cursor inside the picker area to choose a color. Input Hex or RGB values to search for a specific color.`,
        },
        {
          title: "Strokes",
          text: `Add some outlines to your objects and choose the color and thickness.`,
        },
        {
          title: "Skew",
          text: `Skew your object to create a isometric perspective effect.`,
        },
        {
          title: "Drop shadows",
          text: `Add a shadow to your objects and choose the size, orientation and blur.`,
        },
        {
          title: "Rounded corners",
          text: `Add rounded corners to your shapes.`,
        },
        {
          title: "Smart guidelines",
          text: `Get your objects on the right place with the smart guidelines.`,
        },
        {
          title: "Zoom and pan",
          text: `Zoom in and zoom out your presentation to work the details or have a general overview. Easily pan the canvas with your mouse.`,
        },
        {
          title: "Crop",
          text: `Trim and remove unwanted portions of your pictures, gifs or videos.`,
        },
        {
          title: "Font upload",
          text: `Upload your own fonts to Ludus, through OTF and TTF files that are automatically converted to web fonts.`,
        },
        {
          title: "Google Fonts",
          text: `Use any of the 800+ Google Fonts in your presentations.`,
        },
        {
          title: "Multiple styles inside text",
          text: `Use different styles within one text object to highlight important words.`,
        },
        {
          title: "Depth management",
          text: `Send your objects to the back or bring them to the front with dedicated buttons or keyboard shortcuts.`,
        },
        {
          title: "Gradients",
          text: `Create linear or radial gradients with multiple color stops. You can even apply gradient on texts! `,
        },
        {
          title: "Drawing recognition",
          text: `Use the pencil to add shapes and text objects much faster in your slides.`,
        },
        {
          title: "Corner radius",
          text: `Apply corner radius to your pictures or videos. Push it to the max to make it a perfect circle!`,
        },
        {
          title: "Color manager",
          text: `Save and arrange your colors for yourself or for your team! `,
        },
        {
          title: "Trim video",
          text: `Define start and end point of a video.`,
        },
        {
          title: "Image filters",
          text: `Apply contrast, brightness,  saturation, invert color or blur to your images.`,
        },
        {
          title: "Video filters",
          text: `Apply contrast, brightness, invert color or saturation to your video.`,
        },
        {
          title: "Scrollable props",
          text: `Don't lose time by entering a value in a property field. Just click and drag your mouse over the field to change the value!`,
        },
        {
          title: "Copy paste text styles",
          text: `Quickly re-apply the same text style to another text block.`,
        },
        {
          title: "Bulleted/numbered list",
          text: `Add bullets to your text very quickly! Multiple level of indentation are available. Go crazy and use emojis as bullet item! Make it your very own list!`,
        },
        {
          title: "Paragraph spacing",
          text: `Edit the space between paragraph, because white space do matter!`,
        },
        {
          title: "Slide Transitions",
          text: `Define smooth and 3D transition between your slides.`,
        },
        {
          title: "Copy paste slides",
          text: `You can copy and paste slides across your presentations.`,
        },
        {
          title: "Templates",
          text: `Create super advanced templates thanks to our system of object variables (to let you define which one is editable or not). Arrange your templates per theme.`,
        },
        {
          title: "Lines and arrows",
          text: `Have fun with our arrows and lines! Apply dotted stroke, shadows, etc.`,
        },
        {
          title: "Distribute and align objects",
          text: `Select a bunch of object and distribute or align them in one click!`,
        },
        {
          title: "Replace an image",
          text: `Quickly replace an image by another one without changing it's current crop filters or other settings.`,
        },
        {
          title: "Flip objects!",
          text: `Flip images or object horizontally or vertically.`,
        },
        {
          title: "Fit to canvas",
          text: `Quickly fit an image (or a video) to the slide size.`,
        },
        {
          title: "Math expressions in property field!",
          text: `Calculate size of objects directly in the property fields (do something like 1920-500 and it will do the math for you).`,
        },
      ],
    },
    {
      title: "Connect the block",
      details: [
        {
          title: "SVG",
          text: `SVG images are supported by default, alongside JPG, PNG and GIF.`,
        },
        {
          title: "Lottie",
          text: `Insert beautiful SVG animations into your slides thanks to Lottie (by Airbnb).`,
        },
        {
          title: "GIPHY",
          text: `Include your best GIFs and animated stickers into your slides.`,
        },
        {
          title: "Icons",
          text: `Search and add SVG icons from services like Icons8, Iconscout and Noun Project.`,
        },
        {
          title: "Embed anything",
          text: `Easy integration of several third-party contents, such as YouTube or Vimeo videos, or more advanced services like Airtable, Dropbox, GIPHY, Framer, InVision or SketchFab.`,
        },
        {
          title: "Smart Blocks",
          text: `Create Smart Blocks from your objects so they can be used on other slides or other presentations. Edit child text and images via overrides. And why not, start building your design system! `,
        },
        {
          title: "Upload and convert video",
          text: `Your videos are automatically converted to the most common video formats so they can be viewed on any device.`,
        },
        {
          title: "Hyperlinks",
          text: `Add hyperlinks on your objects to go to an external website or skip to another slide.`,
        },
        {
          title: "Code block",
          text: `Add code to your slides in an easy and beautiful way.`,
        },
        {
          title: "Charts",
          text: `Add charts from your tabular data (from spreadsheet tools), make it your own by editing the params and data in Markdown.`,
        },
        {
          title: "Drag and drop",
          text: `You can drop an image, a video or a font and it will convert to something universally readable.`,
        },
        {
          title: "Smart copy and paste",
          text: `Duplicate any objects to get faster at building your presentation. You can also directly paste URLs from your clipboard to embed third-party contents.`,
        },
        {
          title: "Keyboard shortcuts",
          text: `A list of keyboard shortcuts is available and ready to use, adapted to your working habits.`,
        },
        {
          title: "History",
          text: `You made a mistake? No problem. Just press Cmd+Z.`,
        },
        {
          title: "Edit and present from everywhere",
          text: `Get your presentation anytime, anywhere, on any device. Edit it on your computer or iPad and share it by sending a URL link.`,
        },
        {
          title: "Slide manager",
          text: `Arrange your slides like a king by using your mouse or keyboard.`,
        },
        {
          title: "Math equation",
          text: `The math equation block let you use LaTeX syntax to create beautiful equation. `,
        },
        {
          title: "Import PDF",
          text: `Easily import your good old PDF in Ludus, you can even choose to make the texts editable in Ludus.`,
        },
        {
          title: "Export a slide as a PNG/JPG",
          text: `Very simple way to export your current slide to your favorite image format.`,
        },
        {
          title: "Desktop app",
          text: `It's not (yet) a native app, but you can save Ludus as a chrome desktop app. `,
        },
      ],
    },
    {
      title: "Pitch like a boss",
      details: [
        {
          title: "Publish/unpublish",
          text: `Work on your draft version and decide when you're ready to publish your presentation updates to the world. Never share incomplete work!`,
        },
        {
          title: "Password protection",
          text: `Add a password to your presentations to limit the access.`,
        },
        {
          title: "Custom URL",
          text: `Create custom URLs for your presentations.`,
        },
        {
          title: "Custom domain",
          text: `Use your very own domain instead of ludus.one to make Ludus your very own presentation software.`,
        },
        {
          title: "Ludus Vox ",
          text: `Add yourself in video on top of your presentation. So you can be sure your story is always told the right way!`,
        },
        {
          title: "Speaker notes",
          text: `Add speaker notes per slide.`,
        },
        {
          title: "Speaker view",
          text: `View your presentation with your speaker notes on one computer while the audience views the notes-free presentation on a different monitor.`,
        },
        {
          title: "PDF/HTML export",
          text: `If you happen to be at a place without any Internet connection, you can still use our PDF/HTML exports that work perfectly offline.`,
        },
        {
          title: "Web and cloud based",
          text: `Ludus is accessible on the web allowing an access everywhere without the need to install anything on your computer or device. All your data is automatically synchronized and securely stored on our servers.`,
        },
        {
          title: "Automatic playback",
          text: `Let your slides play automatically! (if you need to present at an event).`,
        },
        {
          title: "Contextual menu",
          text: `Like a native application you can access a contextual menu full of often used actions!`,
        },
        {
          title: "Minimal viewer",
          text: `We assume a very clean and unbranded viewer. To make sure your story gets the full attention of the viewer!`,
        },
        {
          title: "Skip slide",
          text: `Hide a slide from the viewer (right click on the slide in your slide manager > skip).`,
        },
      ],
    },
  ]

  return (
    <Layout
      socialDark={true}
      currentPage="all-features"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="The big list of features" />

      <Section>
        <ContentHero
          visualAlt=""
          headlineLabel="Product tour"
          headlineUrl="/product-tour"
          title="The big list of features"
        />
      </Section>

      <Section>
        {featuresList.map((featuresGroup, index) => {
          return (
            <div className="features-wrapper" key={index}>
              <h2>{featuresGroup.title}</h2>
              <div className="features-container" key={index}>
                {featuresGroup.details.map((feature, index) => (
                  <div className="feature-block" key={index}>
                    <h3>
                      {feature.title}
                      {feature.soon ? (
                        <span className="tag-soon">Soon</span>
                      ) : null}
                    </h3>
                    <p>{feature.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </Section>

      <Section>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Section>

      <Section light={true}>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "4",
            width: "35%",
            paddingBottom: "35%",
            bottom: "0",
            right: "0",
            background: `transparent url(${illuReady}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />
        <Ready />
      </Section>
    </Layout>
  )
}

export default ContentSample
